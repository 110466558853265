import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import noAvatarImage from '../assets/img/noAvatar.svg';
import { avatarSelector } from '../reducer/register';

const ErrorSample = () => {
  const avatar = useSelector(avatarSelector);
  const { i18n } = useTranslation();

  return (
    <div>
      <main className="infoDtlPge infoDtlSample infoDtlSampleWrap">
        <div className="infoDtlWrap">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="logoWrap logoPos">el3ment.</div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10 col-sm-12">
                <div className="infoDtlData">
                  <div className="userImgWrap">
                    <img
                      src={avatar ?? noAvatarImage}
                      width="100px"
                      height="100px"
                      className="img-fluid user-img"
                    />
                    <button className="btn btnPlay btncross">
                      <span className="btn-red-circle">
                        <span>&#10005;</span>
                      </span>
                    </button>
                  </div>
                  <div className="userInfoName">
                    <h2>{i18n.t('paragraphs.errorSample')}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ErrorSample;
