import { Routes, Route } from 'react-router-dom';

import AddSample from './component/Addsample';
import ShowSample from './component/Showsample';
import TrimSample from './component/Trimsample';
import ErrorSample from './component/Errorsample';
import Redirection from './component/Redirect';

import './assets/css/App.css';

function App() {
  return (
    <>
      <Routes>
        <Route index path="/" element={<Redirection />} />
        <Route path="/trim-sample/:id" element={<TrimSample />} />
        <Route path="/show-sample" element={<ShowSample />} />
        <Route path="/error-sample" element={<ErrorSample />} />
        <Route path=":id" element={<AddSample />} />
      </Routes>
    </>
  );
}

export default App;
