import React, { createContext, useState } from 'react';

const FileContext = createContext();

const FileContextProvider = ({ children }) => {
  const [fileURL, setFileURL] = useState('');
  const [fileName, setFileName] = useState('');
  return (
    <FileContext.Provider value={{ fileURL, setFileURL, fileName, setFileName }}>
      {children}
    </FileContext.Provider>
  );
};

export { FileContext, FileContextProvider };
