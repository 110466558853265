import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';

import registerReducer from '../reducer/register';

export default function initializeStore(initialState = {}) {
  const rootReducer = combineReducers({
    registerReducer,
  });

  const store = createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(thunk),
      typeof window !== 'undefined' && window.devToolsExtension
        ? window.devToolsExtension()
        : (f) => f,
    ),
  );
  return store;
}
