import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AudioPlayerProvider } from 'react-use-audio-player';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import noAvatarImage from '../assets/img/noAvatar.svg';
import iconPlay from '../assets/img/icoPlay.png';
import Vector from '../assets/img/Vector.png';
import WhitePlayIcon from '../assets/img/whitePlay.svg';
import { FileContext } from '../contexts/fileContext';
import {
  avatarSelector,
  trimBeginSelector,
  uploadingSelector,
  uploadSuccessSelector,
  urlInfoSelector,
  errorSelector,
} from '../reducer/register';
import { trimAndUpload } from '../actions/api';

import AudioPlayer from './AudioPlayer';

const TrimSample = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const token = params.id;

  const trimBegin = useSelector(trimBeginSelector);
  const avatar = useSelector(avatarSelector);
  const urlInfoData = useSelector(urlInfoSelector);
  const uploadSucess = useSelector(uploadSuccessSelector);
  const uploading = useSelector(uploadingSelector);
  const error = useSelector(errorSelector);

  const { fileURL, fileName } = useContext(FileContext);
  const file = useMemo(() => (fileURL ? URL.createObjectURL(fileURL) : undefined), [fileURL]);

  const openChooseFile = useCallback(() => {
    if (fileURL && trimBegin !== undefined) {
      dispatch(trimAndUpload(token, fileURL, trimBegin));
    }
  }, [fileURL, trimBegin]);

  useEffect(() => {
    if (!uploading && !!uploadSucess) {
      uploadSucess ? navigate('/show-sample') : navigate('/error-sample');
    }
  }, [uploadSucess, uploading]);

  useEffect(() => {
    if (error) {
      navigate('/error-sample');
    }
  }, [error]);

  return (
    <main className="infoDtlPge infoDtlSample trimsample">
      <div className="infoDtlWrap">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="logoWrap">el3ment.</div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-sm-12">
              <div className="infoDtlData">
                <div className="userImgWrap">
                  <img
                    src={avatar || noAvatarImage}
                    width="100px"
                    height="100px"
                    className="img-fluid user-img"
                  />
                  <button className="btn btnPlay">
                    <img src={iconPlay} className="img-fluid" width="8px" height="10px" />
                  </button>
                </div>
                <div className="userInfoName">
                  <h2>{i18n.t('headings.trimSample')}</h2>
                  <p>{i18n.t('paragraphs.trimSample')}</p>
                </div>
              </div>
              <div className="infoMdlDtl">
                <div className="row justify-content-center">
                  <div className="col-lg-6 col-md-10">
                    <ul className="audioListing">
                      <li className="border-bottom">
                        <div className="leftPanel">
                          <div className="imgWrap">
                            <div className="playIconContainer">
                              <img src={WhitePlayIcon} className="img-fluid" />
                            </div>
                          </div>
                          <div className="dataWrap">
                            <h2>{`${i18n.t('labels.trimSample.uploadedFile')}:`}</h2>
                            <p>{fileName}</p>
                          </div>
                        </div>
                        <div className="rightPanel">
                          <button className="btn default-cursor" disabled={uploading}>
                            <img src={Vector} className="img-fluid" />
                          </button>
                        </div>
                      </li>
                      {file && (
                        <AudioPlayerProvider>
                          <AudioPlayer file={file} isMarkerDisabled={uploading} />
                        </AudioPlayerProvider>
                      )}
                    </ul>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="ctaSample">
                      <button
                        onClick={openChooseFile}
                        disabled={!fileURL && !urlInfoData}
                        className="btn btn-row"
                      >
                        <p
                          className="upload-text"
                          style={uploading ? { paddingLeft: '20px' } : undefined}
                        >
                          {uploading
                            ? `${i18n.t('labels.trimSample.uploading')}..`
                            : `${i18n.t('labels.trimSample.upload')}`}
                        </p>
                        {uploading && <div className="loader"></div>}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default TrimSample;
