import axios from 'axios';
import * as types from '../types/type';

const INFO_URL = 'https://api-stage.el3mentapp.com/track-upload/info';
const UPLOAD_URL = 'https://api-stage.el3mentapp.com/track-upload/url';

export const uploadInfo = (token) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .get(INFO_URL, { headers: { Authorization: token } })
      .then((response) => {
        if (response) {
          dispatch({ type: types.REGISTER_SUCCESS, payload: response });
          dispatch({ type: types.SET_AVATAR, payload: response?.data?.avatar });
          return resolve(response);
        } else {
          dispatch({ type: types.REGISTER_ERROR });
          return resolve({ message: 'Error' });
        }
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export const trimAndUpload = (token, file, start_trim) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: types.SET_UPLOADING, payload: true });
    axios
      .post(
        UPLOAD_URL,
        { start_trim: start_trim, file_name: file.name },
        { headers: { Authorization: token } },
      )
      .then((response) => {
        if (response) {
          dispatch({ type: types.REGISTER_SUCCESS, payload: response });
          dispatch({ type: types.SET_URL_INFO, payload: response });

          const { url, fields } = response.data;
          const {
            'Content-Disposition': contentDisposition,
            acl,
            key,
            'x-amz-meta-type': xAmzMetaType,
            'x-amz-meta-user-id': xAmzMetaUserId,
            'x-amz-security-token': xAmzSecurityToken,
            'x-amz-meta-track-id': xAmzMetaTrackId,
            'x-amz-meta-trim': xAmzMetaTrim,
            'x-amz-meta-file-name': xAmzMetaFileName,
            AWSAccessKeyId: awsAccessKeyId,
            policy,
            signature,
          } = fields;
          const formData = new FormData();
          formData.append('Content-Disposition', contentDisposition);
          formData.append('Content-Type', '*');
          formData.append('acl', acl);
          formData.append('key', key);
          formData.append('x-amz-meta-user-id', xAmzMetaUserId);
          formData.append('x-amz-security-token', xAmzSecurityToken);
          formData.append('x-amz-meta-type', xAmzMetaType);
          formData.append('x-amz-meta-track-id', xAmzMetaTrackId);
          formData.append('x-amz-meta-trim', xAmzMetaTrim);
          formData.append('x-amz-meta-file-name', xAmzMetaFileName);
          formData.append('AWSAccessKeyId', awsAccessKeyId);
          formData.append('policy', policy);
          formData.append('signature', signature);
          formData.append('file', file);

          fetch(url, {
            method: 'POST',
            body: formData,
          })
            .then((_) => {
              dispatch({ type: types.UPLOAD_SUCCESS });
            })
            .catch((err) => {
              dispatch({ type: types.UPLOAD_ERROR });
              return reject(err);
            });
        } else {
          dispatch({ type: types.REGISTER_ERROR });
          return resolve({ message: 'Error' });
        }
      })
      .catch((err) => {
        dispatch({ type: types.REGISTER_ERROR, error: 'Error' });
        return reject(err);
      });
  });
};
