import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAudioPlayer, useAudioPosition } from 'react-use-audio-player';
import ReactSlider from 'react-slider';
import { useTranslation } from 'react-i18next';

import WhitePlayIcon from '../assets/img/whitePlay.svg';
import PauseIcon from '../assets/img/pause.svg';
import { setTrimBegin } from '../actions';
import { trimBeginSelector } from '../reducer/register';

const SAMPLE_DURATION = 20;

const AudioPlayer = React.memo(({ file, isMarkerDisabled }) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const trimBegin = useSelector(trimBeginSelector);
  const { duration, seek, position } = useAudioPosition({ highRefreshRate: true });
  const { togglePlayPause, ready, loading, playing, pause } = useAudioPlayer({
    src: file,
    format: 'mp3',
    autoplay: false,
  });

  const width = (432 * SAMPLE_DURATION) / (duration < SAMPLE_DURATION ? SAMPLE_DURATION : duration);

  useEffect(() => {
    if (Math.ceil(position) === trimBegin + SAMPLE_DURATION) {
      seek(trimBegin);
    }
  }, [position, seek, trimBegin]);

  useEffect(() => {
    isMarkerDisabled && pause();
  }, [isMarkerDisabled]);

  const onChange = useCallback(
    (arr) => {
      pause();
      dispatch(setTrimBegin(arr));
      seek(arr);
    },
    [dispatch, seek],
  );

  const formatTime = (duration) => {
    const minutes = Math.floor(duration / 60);
    const seconds = Math.round(duration % 60);
    return `${minutes < 10 ? 0 : ''}${minutes}:${seconds < 10 ? 0 : ''}${seconds}`;
  };

  if (!ready && !loading)
    return <div className="loading-text">{i18n.t('labels.audioPlayer.noAudio')}</div>;
  if (loading)
    return <div className="loading-text">{i18n.t('labels.audioPlayer.loadingAudio')}</div>;

  return (
    <div className="sample-duration-group">
      <div className="row">
        <div className="column">
          <ReactSlider
            className="progress-bar"
            defaultValue={0}
            thumbClassName="track-thumb"
            trackClassName="example-track"
            max={duration - 20}
            disabled={isMarkerDisabled}
            onChange={onChange}
            renderThumb={(props, _) => (
              <div {...props} className="marker">
                <div className="white-bottom" style={{ width: width }} />
                <div className="duration-marker">
                  {[...Array(3).keys()].map((index) => (
                    <div key={index} className="slider-row">
                      <div className="slider-point" />
                      <div className="slider-point" />
                    </div>
                  ))}
                </div>
                <div className="duration-marker-triangle" />
              </div>
            )}
          />
          <div className="row space-between">
            <p className="audio-paragraph-text">{i18n.t('labels.audioPlayer.sample20s')}</p>
            <p className="audio-paragraph-text">
              {formatTime(trimBegin)}-{formatTime(trimBegin + SAMPLE_DURATION)}
            </p>
          </div>
        </div>
        <button className="play-pause-button" onClick={togglePlayPause} disabled={isMarkerDisabled}>
          <img src={!playing ? WhitePlayIcon : PauseIcon} width="8px" height="9px" />
        </button>
      </div>
    </div>
  );
});

export default AudioPlayer;
