import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import $ from 'jquery';
import { useTranslation } from 'react-i18next';

import iconPlay from '../assets/img/icoPlay.png';
import ThumbnailImg1 from '../assets/img/ThumbnailImg-1.png';
import Vector from '../assets/img/Vector.png';
import ImgArrow from '../assets/img/ImgArrow.png';
import noAvatarImage from '../assets/img/noAvatar.svg';
import CheckMark from '../assets/img/checkmark.svg';
import WhitePlayIcon from '../assets/img/whitePlay.svg';
import icoUsa from '../assets/img/usa-flag.png';
import icoSpain from '../assets/img/spain-flag.png';
import { FileContext } from '../contexts/fileContext';
import { uploadInfo, selectFile } from '../actions';

const AddSample = () => {
  const params = useParams();
  const token = params.id;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const [isChecked, setIsChecked] = useState(false);
  const [file, setFile] = useState(null);
  const { setFileURL, setFileName } = useContext(FileContext);
  const [artistImage, setArtistImage] = useState(noAvatarImage);
  const [uploadInformations, setUploadInformation] = useState();
  const [defLang, setDefLang] = useState('English');
  const [issOpen, setIsOpen] = useState(false);
  const [isMaxSizeExceeded, setIsMaxSizeExceeded] = useState(false);

  useEffect(() => {
    if (token) {
      fetchUploadInfo();
    }

    if (file && token) {
      setFileURL(file);
      dispatch(selectFile(file));
      navigate(`/trim-sample/${token}`);
    }
  }, [file, setFileURL, token]);

  const fetchUploadInfo = async () => {
    try {
      const uploadStuff = await dispatch(uploadInfo(token));
      localStorage.setItem('infoStore', JSON.stringify(uploadStuff?.data));
      setArtistImage(uploadStuff?.data.avatar);
      setUploadInformation(uploadStuff?.data);
    } catch (error) {
      navigate('/error-sample');
    }
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const switchLangEng = (language) => {
    switch (language) {
      case 'English':
        setDefLang('English');
        changeLanguage('en');
        break;
      case 'Spain':
        setDefLang('Spain');
        changeLanguage('es');
        break;
      default:
        break;
    }

    toggleMenu();
  };

  const toggleMenu = () => {
    if (issOpen) {
      $('#nav-icon').removeClass('open');
      $('#navbarContent').removeClass('show');
    } else {
      $('#nav-icon').addClass('open');
      $('#navbarContent').addClass('show');
    }
    setIsOpen(!issOpen);
  };

  const handleChange = async (e) => {
    const fl = e.target.files[0];

    if (fl) {
      if (fl.size > 10 * 1024 * 1024) {
        setIsMaxSizeExceeded(true);
      } else {
        setFileName(fl.name);
        setFile(fl);
      }
    }
  };

  return (
    <>
      <main className="infoDtlPge infoDtlSample">
        <div className="infoDtlWrap">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <nav className="navbar navbar-expand-md topnav">
                  <div className="logoWrap topnav-centered">el3ment.</div>
                  <div className="dropdown scrDropdown mobile-none topnav-right">
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {defLang === 'English' && (
                        <>
                          <img src={icoUsa} className="img-fluid" /> {defLang}
                        </>
                      )}
                      {defLang === 'Spain' && (
                        <>
                          <img src={icoSpain} className="img-fluid" /> {defLang}
                        </>
                      )}
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <li>
                        <a onClick={() => switchLangEng('English')}>
                          <img src={icoUsa} className="img-fluid" />
                          <span>English</span>
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => {
                            switchLangEng('Spain');
                          }}
                        >
                          <img src={icoSpain} className="img-fluid" />
                          <span>Spain</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10 col-sm-12">
                <div className="infoDtlData">
                  <div className="userImgWrap">
                    <img
                      src={artistImage || noAvatarImage}
                      width="100px"
                      height="100px"
                      className="img-fluid user-img"
                    />
                    <button className="btn btnPlay">
                      <img src={iconPlay} className="img-fluid" />
                    </button>
                  </div>
                  <div className="userInfoName">
                    <h2>{i18n.t('headings.addSample')}</h2>
                    <p>{i18n.t('paragraphs.addSample')}</p>
                  </div>
                </div>
                <div className="infoMdlDtl">
                  <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-10">
                      <ul className="audioListing">
                        <li>
                          <div className="leftPanel">
                            <div className="imgWrap">
                              {uploadInformations?.replaceTrackCover ? (
                                <div className="track-cover">
                                  <img
                                    src={uploadInformations?.replaceTrackCover || ThumbnailImg1}
                                    className="img-fluid round"
                                    width="38px"
                                    height="38px"
                                  />
                                </div>
                              ) : (
                                <div className="playIconContainer">
                                  <img
                                    src={WhitePlayIcon}
                                    className="img-fluid"
                                    width="8px"
                                    height="10px"
                                  />
                                </div>
                              )}
                            </div>
                            <div className="dataWrap trackName">
                              <h2>
                                {uploadInformations?.replaceTrackName
                                  ? `${i18n.t('labels.addSample.replacing')}:`
                                  : `${i18n.t('labels.addSample.adding')}:`}
                              </h2>
                              <p>
                                {uploadInformations?.replaceTrackName ||
                                  i18n.t('labels.addSample.sampleName')}
                              </p>
                            </div>
                          </div>
                          <div className="rightPanel">
                            <div className="btn default-cursor ">
                              <img src={Vector} className="img-fluid" />
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="leftPanel">
                            <div className="checkBoxWrap">
                              <form>
                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    id="termsAgree"
                                    onChange={() => setIsChecked(!isChecked)}
                                  />
                                  <label htmlFor="termsAgree">
                                    <div
                                      className="check-circle"
                                      style={isChecked ? { backgroundColor: 'white' } : undefined}
                                    >
                                      {isChecked && <img src={CheckMark} />}
                                    </div>
                                  </label>
                                </div>
                              </form>
                            </div>
                            <div className="dataWrap">
                              <h2>{i18n.t('labels.addSample.agree')}</h2>
                              <p>{i18n.t('labels.addSample.communityGuidelines')}</p>
                            </div>
                          </div>
                          <div className="rightPanel">
                            <a
                              href="https://cdn-stage.el3mentapp.com/landing/terms.pdf?v=20210722"
                              target="_blank"
                              className="btn"
                              rel="noreferrer"
                            >
                              <span className="read-text">{i18n.t('labels.addSample.read')}</span>
                              <img src={ImgArrow} className="img-fluid" />
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="ctaSample">
                        <input
                          type="file"
                          name="file"
                          id="file"
                          accept="audio/mp3, audio/wav, audio/mpeg, audio/x-mpeg, audio/x-wav, audio/x-m4a, audio/mpeg3, audio/x-mp3, audio/x-mpeg3, audio/amr, audio/x-m4p, audio/x-m4b"
                          multiple={false}
                          maxLength={10 * 1024 * 1024}
                          className="inputfile"
                          disabled={!isChecked}
                          onChange={handleChange}
                        />
                        <label htmlFor="file" className={!isChecked ? 'disabled' : undefined}>
                          {i18n.t('labels.addSample.selectFile')}
                        </label>
                        {isMaxSizeExceeded && (
                          <p className="error-message">{i18n.t('errorMessages.fileLimit')}</p>
                        )}
                        <a href="https://support.el3mentapp.com/" target="_blank" rel="noreferrer">
                          {i18n.t('labels.addSample.needHelp')}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

AddSample.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

export default AddSample;
