import {
  SELECT_FILE,
  SET_AVATAR,
  SET_TRIM_BEGIN,
  SET_UPLOADING,
  SET_URL_INFO,
} from '../types/type';
import { uploadInfo, trimAndUpload } from './api';

const selectFile = (payload) => ({
  type: SELECT_FILE,
  payload,
});

const setTrimBegin = (payload) => ({
  type: SET_TRIM_BEGIN,
  payload,
});

const setAvatar = (payload) => ({
  type: SET_AVATAR,
  payload,
});

const setUrlInfo = (payload) => ({
  type: SET_URL_INFO,
  payload,
});

const setUploading = (payload) => ({
  type: SET_UPLOADING,
  payload,
});

export { uploadInfo, selectFile, setTrimBegin, setAvatar, setUrlInfo, trimAndUpload, setUploading };
