import {
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  SELECT_FILE,
  SET_TRIM_BEGIN,
  SET_AVATAR,
  SET_URL_INFO,
  UPLOAD_SUCCESS,
  UPLOAD_ERROR,
  SET_UPLOADING,
} from '../types/type';

const initialState = {
  registerdata: [],
  file: '',
  avatar: undefined,
  error: '',
  trimBegin: 0,
  urlInfo: null,
  uploadSuccess: null,
  uploading: false,
};

const registerReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case REGISTER_SUCCESS: {
      return {
        ...state,
        ...payload,
        registerdata: action.payload,
      };
    }
    case REGISTER_ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }
    case SELECT_FILE: {
      return {
        ...state,
        file: action.payload,
      };
    }
    case SET_TRIM_BEGIN: {
      return {
        ...state,
        trimBegin: action.payload,
      };
    }
    case SET_AVATAR: {
      return {
        ...state,
        avatar: action.payload,
      };
    }
    case SET_URL_INFO: {
      return {
        ...state,
        urlInfo: action.payload,
      };
    }
    case SET_UPLOADING: {
      return {
        ...state,
        uploading: action.payload,
      };
    }
    case UPLOAD_SUCCESS: {
      return {
        ...state,
        uploading: false,
        uploadSuccess: true,
      };
    }
    case UPLOAD_ERROR: {
      return {
        ...state,
        uploading: false,
        uploadSuccess: false,
      };
    }
    default:
      return state;
  }
};

export const trimBeginSelector = (state) => state.registerReducer.trimBegin;
export const avatarSelector = (state) => state.registerReducer.avatar;
export const urlInfoSelector = (state) => state.registerReducer.urlInfo;
export const uploadSuccessSelector = (state) => state.registerReducer.uploadSuccess;
export const uploadingSelector = (state) => state.registerReducer.uploading;
export const errorSelector = (state) => state.registerReducer.error;

export default registerReducer;
