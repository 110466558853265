import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import store from '../src/store/index';
import App from './App';
import { FileContextProvider } from './contexts/fileContext';

import { I18nextProvider } from 'react-i18next';
import i18n from './locales/i18n';

ReactDOM.render(
  <Provider store={store()}>
    <I18nextProvider i18n={i18n}>
      <React.StrictMode>
        <FileContextProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </FileContextProvider>
      </React.StrictMode>
    </I18nextProvider>
  </Provider>,
  document.getElementById('root'),
);
